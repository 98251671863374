<template>
    <v-container>
        <v-row>
            <v-col v-if="mostrarFiltroGrupo">
                <v-autocomplete
                label="Periodo"
                item-text="nombre"
                item-value="id"
                :items="periodos"                
                v-model="periodoSeleccionado"                
                return-object
                @change="limpiarResultados"
                ></v-autocomplete>
            </v-col>

            <v-col v-if="mostrarFiltroPeriodo">
                <v-autocomplete
                label="Grupos"
                item-text="nombre"
                item-value="id"
                :items="grupos"
                return-object
                v-model="grupoSeleccionado"  
                @change="limpiarResultados"              
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row v-if="mostrarFiltroEstudiante">
            <v-col>
                <v-autocomplete clearable
                label="Estudiante"
                item-text="nombreCompleto"
                item-value="estudianteId"
                :items="alumnos"
                v-model="estudianteDestinatario"   
                return-object
                @change="limpiarResultados"                             
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <template>
                    <v-menu
                        ref="menu"
                        v-model="menus.menuFiltroFechaInicial"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :nudge-right="40"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="fechaInicial"
                                label="Fecha Inicial"
                                prepend-icon="mdi-calendar"
                                readonly
                                persistent-hint
                                v-bind="attrs"
                                v-on="on"
                                @blur="limpiarResultados"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            ref="picker"
                            v-model="fechaInicial"
                            :max="new Date(new Date().getFullYear(), 11, 31).toISOString().substr(0, 10)"
                            :min="new Date(new Date().getFullYear(), 0, 1).toISOString().substr(0, 10)"
                            @input="menus.menuFiltroFechaInicial = false"
                        ></v-date-picker>
                    </v-menu>
                </template>
            </v-col>
            <v-col>
                <template>
                    <v-menu
                        ref="menu"
                        v-model="menus.menuFiltroFechaFin"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :nudge-right="40"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="fechaFinal"
                                label="Fecha Final"
                                prepend-icon="mdi-calendar"
                                readonly
                                persistent-hint
                                v-bind="attrs"
                                v-on="on"
                                @blur="limpiarResultados"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                                ref="picker"
                                v-model="fechaFinal"
                                :max="new Date(new Date().getFullYear(), 11, 31).toISOString().substr(0, 10)"
                                :min="new Date(new Date().getFullYear(), 0, 1).toISOString().substr(0, 10)"
                                @input="menus.menuFiltroFechaFin = false"
                        ></v-date-picker>
                    </v-menu>
                </template>
            </v-col>            
        </v-row>
        <v-row>
            <v-col cols="10">
                <v-autocomplete
                label="Tipo Evento"
                item-text="nombre"
                item-value="id"
                :items="tiposEvento"
                v-model="tipoNotificacionId"
                clearable
                @change="limpiarResultados"
                ></v-autocomplete>
            </v-col>
             <v-col cols="2">                
                <v-switch v-model="verInactivos" label="Ver Inactivos" class="mt-2"  color="info" @change="limpiarResultados"></v-switch>                      
             </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn color="secondary" @click="consultar" :disabled="deshabilitarConsultar">Consultar</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="agendaEventos"
                    :items-per-page="5"
                    class="elevation-1"
                >                    
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Agenda</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>

                            <v-dialog v-model="dialogHistorial" max-width="80%" scrollable>
                                <v-card>
                                    <v-card-title class="blue-grey white--text"><span class="text-h6">Historial De Respuestas</span></v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-container> 
                                            <template>
                                                <v-timeline dense clipped>
                                                    <v-timeline-item
                                                        v-for="(item, i) in eventoHistorial"
                                                        :key="i"
                                                        :color="obtenerColorEstado(item.aceptado)"
                                                        small                                                 
                                                        fill-dot
                                                    > 
                                                        <v-row>
                                                            <v-col cols="2">
                                                                <span class="font-weight-black">Fecha:</span><span>{{format_date(item.fechaRegistro)}}</span>
                                                            </v-col>
                                                            <v-col cols="2">
                                                                <span class="font-weight-black">Estado:</span><span>{{obtenerEstado(item.aceptado)}}</span>
                                                            </v-col>                                                                    
                                                            <v-col cols="4">
                                                                <span class="font-weight-black">Usuario:</span><span>{{item.asuntoUsuario.nombres}} {{item.asuntoUsuario.apellidos}}</span>
                                                            </v-col>
                                                            <v-col cols="4" v-if="item.telefonoContacto!=null && item.telefonoContacto.length>0">
                                                                <span class="font-weight-black">Teléfono:</span><span>{{item.telefonoContacto}}</span>
                                                            </v-col>                                                                                                                                  
                                                        </v-row>                                                                
                                                        <v-row v-if="item.observaciones!=null && item.observaciones.length>0 && item.aceptado!=null">                                                                    
                                                            <v-col cols="12">
                                                                <span class="font-weight-black">Observaciones:</span>
                                                                <span>{{item.observaciones}}</span>
                                                            </v-col> 
                                                        </v-row>
                                                    </v-timeline-item>                                                    
                                                </v-timeline>
                                            </template>
                                        </v-container>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeHistorial">Salir</v-btn>                                                                                
                                    </v-card-actions>
                                </v-card>                                
                            </v-dialog>

                            <v-dialog v-model="dialog" max-width="80%" persistent >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="secondary"
                                        dark
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"                   
                                        :disabled="deshabilitarAgregarEvento"                     
                                        @click="btnNuevoEventoClick"
                                    >
                                        Nuevo Evento
                                    </v-btn>
                                </template>

                                <v-card>
                                    <v-card-title class="blue-grey white--text"><span class="text-h6">{{editedItem.accion}}</span></v-card-title>
                                    <v-card-text>
                                        <v-container>  
                                            <v-row>
                                                <v-col cols="3">                                                    
                                                     <span class="font-weight-black">Periodo : </span><span>{{editedItem.periodo.nombre}}</span>
                                                </v-col>
                                                <v-col cols="3">
                                                    <span class="font-weight-black">Grupo : </span><span>{{editedItem.grupo.nombre}}</span>
                                                </v-col>
                                                <v-col cols="6">
                                                    <span class="font-weight-black">{{obtenerCantidadDestinatariosEvento}} {{obtenerNombreDestinatarioEvento}}</span>
                                                </v-col>                                                
                                            </v-row>  
                                            <v-row>
                                                <v-col cols="6">
                                                    <span class="font-weight-black">Responsable : </span><span>{{obtenerNombreResponsableEvento}}</span>
                                                </v-col>                                                                                                         
                                                <v-col cols="6">
                                                    <span class="font-weight-black">Fecha Registro : </span><span>{{editedItem.fechaRegistro}}</span>
                                                </v-col>                                                                                                  
                                            </v-row>
                                            <v-row v-if="editedItem!=null && editedItem.estadoRespuesta!=null && editedItem.estadoRespuesta.length>0">
                                                <v-col cols="12">
                                                    <span class="font-weight-black">Estado Respuesta : </span><span class="font-weight-black"  v-bind:class="[editedItem.colorEstadoRespuesta]">{{editedItem.estadoRespuesta}}</span>
                                                </v-col>      
                                            </v-row>
                                            <v-row>
                                                <v-col cols="3">
                                                    <template>
                                                        <v-menu
                                                            ref="menu"
                                                            v-model="menus.menuFechaInicialEvento"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            :nudge-right="40"
                                                            offset-y
                                                            min-width="auto"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="editedItem.fechaInicialFecha"
                                                                    label="Fecha Inicial"
                                                                    prepend-icon="mdi-calendar"
                                                                    readonly
                                                                    persistent-hint
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    @change="validarEncabezadoEvento()"                                                                    
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                    ref="picker"
                                                                    v-model="editedItem.fechaInicialFecha"
                                                                    :max="new Date(new Date().getFullYear(), 11, 31).toISOString().substr(0, 10)"
                                                                    :min="new Date(new Date().getFullYear(), 0, 1).toISOString().substr(0, 10)"
                                                                    @input="menus.menuFechaInicialEvento = false;validarEncabezadoEvento();"
                                                            ></v-date-picker>
                                                        </v-menu>
                                                    </template>
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        v-model="editedItem.fechaInicialHora"
                                                        label="Hora Inicio"                                                        
                                                        type="time"
                                                        suffix="PST"
                                                        @blur="validarEncabezadoEvento()"
                                                        :readonly="mostrarSoloLectura"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="3">
                                                    <template>
                                                        <v-menu
                                                            ref="menu"
                                                            v-model="menus.menuFechaFinalEvento"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            :nudge-right="40"
                                                            offset-y
                                                            min-width="auto"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="editedItem.fechaFinalFecha"
                                                                    label="Fecha Fin"
                                                                    prepend-icon="mdi-calendar"
                                                                    readonly
                                                                    persistent-hint
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    @change="validarEncabezadoEvento()"                                                                    
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                    ref="picker"
                                                                    v-model="editedItem.fechaFinalFecha"
                                                                    :max="new Date(new Date().getFullYear(), 11, 31).toISOString().substr(0, 10)"
                                                                    :min="new Date(new Date().getFullYear(), 0, 1).toISOString().substr(0, 10)"
                                                                    @input="menus.menuFechaFinalEvento = false;validarEncabezadoEvento();"
                                                            ></v-date-picker>
                                                        </v-menu>
                                                    </template>
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        v-model="editedItem.fechaFinalHora"
                                                        label="Hora Fin"                                                        
                                                        type="time"
                                                        suffix="PST"
                                                        @blur="validarEncabezadoEvento()"
                                                        :readonly="mostrarSoloLectura"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="editedItem.asunto"                                                                                                        
                                                        label="Asunto"
                                                        counter="200"
                                                        required
                                                        @blur="validarEncabezadoEvento()"
                                                        :readonly="mostrarSoloLectura"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="10">                                                    
                                                    <v-autocomplete
                                                        label="Tipo Evento"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        :items="tiposEvento"
                                                        v-model="editedItem.tipoEvento"
                                                        return-object
                                                        @change="validarEncabezadoEvento()"
                                                        :readonly="mostrarSoloLectura"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-switch v-model="editedItem.activo" label="Activo" class="mt-2"  color="info" @change="validarEncabezadoEvento()" :readonly="mostrarSoloLectura"></v-switch>                      
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-textarea
                                                        counter="2000"
                                                        label="Observación"
                                                        v-model="editedItem.observaciones"
                                                        @blur="validarEncabezadoEvento()"
                                                        :readonly="mostrarSoloLectura"
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                            <v-divider></v-divider>
                                            <v-row v-if="mostrarBotonesRespuesta">
                                                <v-col cols="12">
                                                    <v-textarea
                                                            counter="1000"
                                                            label="*Respuesta (Requerido)"
                                                            v-model="editedItem.respuestaObservaciones"                                                        
                                                    ></v-textarea>
                                                </v-col>                                                
                                            </v-row>

                                            <v-row v-if="mostrarBotonesRespuesta">
                                                <v-col cols="12">
                                                    <v-text-field
                                                            counter="200"
                                                            label="*Teléfono Contacto (Requerido)"
                                                            v-model="editedItem.telefonoContacto"                                                        
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>

                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>

                                        <v-btn color="blue darken-1" text @click="close">
                                        Salir
                                        </v-btn>

                                        <v-btn
                                            v-if="mostrarBotonGuardarModificar"
                                            color="blue darken-1"
                                            text
                                            :disabled="deshabilitarModificarGuardar"
                                            @click="save"
                                        >
                                        Guardar
                                        </v-btn>

                                        <v-btn color="green darken-1" text v-if="mostrarBotonesRespuesta" @click="saveRespuesta(true)">
                                        Aceptar
                                        </v-btn>

                                        <v-btn color="red darken-1" text v-if="mostrarBotonesRespuesta" @click="saveRespuesta(false)">
                                        Rechazar
                                        </v-btn>

                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>

                    <template v-slot:[`item.fechainicioevento`]="{ item }">
                        <td>{{format_date(item.fechaInicioEvento)}}</td>
                    </template>

                    <template v-slot:[`item.fechafinevento`]="{ item }">
                        <td>{{format_date(item.fechaFinEvento)}}</td>
                    </template>

                    <template v-slot:[`item.asunto`]="{ item }">
                        <td>{{item.asunto.substring(0, 30)}}</td>
                    </template>

                    <template v-slot:[`item.destinatariosuario`]="{ item }">
                        <td>{{obtenerNombreDestinatario(item.destinatarioUsuario)}}</td>
                    </template>

                    <template v-slot:[`item.activo`]="{ item }">
                        <v-simple-checkbox v-model="item.activo" disabled ></v-simple-checkbox>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon class="mr-2" @click="verLecturaEncabezadoEvento(item)"> mdi-magnify-plus-outline </v-icon>          
                        <v-icon class="mr-2" @click="verEditarEncabezadoEvento(item)" v-if="mostrarEditarEncabezado"> mdi-pencil </v-icon>          
                        <v-icon class="mr-2" @click="verHistorial(item)"> mdi-account-clock </v-icon>                                  
                        <v-icon class="mr-2" @click="verResponderEvento(item)" v-if="item.responsableUsuario.id!=idUsuario"> mdi-reply </v-icon>                                  
                    </template>

                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from "vuex";


export default {
    data: () => ({
        periodoSeleccionado :{id:null,nombre:null},        
        grupoSeleccionado :{id:null,nombre:null},        
        estudianteDestinatario: {estudianteId:0,nombreCompleto:""},
        mostrarFiltroEstudiante:false,
        mostrarBotonGuardarModificar:false,
        mostrarSoloLectura : false,
        mostrarBotonesRespuesta:false,
        mostrarEditarEncabezado:false,
        mostrarFiltroPeriodo:true,
        mostrarFiltroGrupo : true,
        tipoNotificacionId:0,
        verInactivos:false,
        alumnos: [],
        periodos: [],
        grupos: [],
        tiposEvento:[],
        fechaInicial:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()).toISOString().substr(0, 10),
        fechaFinal:new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()+10).toISOString().substr(0, 10),
        tiposNotificacion : [],        
        headers: 
        [
            { text: "Fecha Inicio", value: "fechainicioevento" },            
            { text: "Fecha Fin", value: "fechafinevento" },            
            { text: "Tipo", value: "tipoEvento.nombre" },            
            { text: "Grupo", value: "grupo.nombre" },            
            { text: "Destinatario", value: "destinatariosuario" },            
            { text: "Asunto", value: "asunto" },                      
            { text: "Activo", value: "activo" },   
            { text: "Acciones", value: "actions", sortable: false },
        ],
        editedItem :
        {
            id:0,
            periodo:{id:0,nombre:'Periodo'},
            grupo:{id:0,nombre:'Periodo'},
            tipoEvento:{id:0,nombre:'Tipo Evento'},
            tipoEventoId:0,
            fechaRegistro:new Date().toISOString(),
            fechaInicioEvento:null,
            fechaFinEvento:null,
            fechaInicialFecha:null,
            fechaInicialHora:null,
            fechaFinalFecha:null,
            fechaFinalHora:null,
            destinatarioUsuario:null,
            responsableUsuario:null,
            eventoDetalles:[],
            asunto:null,
            observaciones:null,
            activo : true,
            estadoRespuesta:"PENDIENTE",
            colorEstadoRespuesta:"",
            accion:""
        },
        menus:{
            menuFiltroFechaInicial : false,
            menuFiltroFechaFin:false,
            menuFechaInicialEvento : false,
            menuFechaFinalEvento:false
        },
        dialog:false,
        dialogHistorial:false,
        eventoHistorial:[],
        deshabilitarModificarGuardar:true,
        agendaEventos:[],
        datosFiltro:
        {
            periodo:0,
            grupo:0,
            destinatario:null,
            fechaInicial : null,
            fechaFinal:null,
            tipoEvento : 0,
            responsable:null,                                                
            verInactivos:false
        },
        rolesUsuario:[],
        idUsuario : null,
        usuarioSesion:null
    }),
    created() {
        this.initialize();
    },
    computed: {
        estudiantesGrupos() {
            if (this.estudianteDestinatario!=null && this.estudianteDestinatario.estudianteId !== "-1") {
                return this.alumnos.filter((x) => {
                return x.estudianteId === this.estudianteDestinatario.estudianteId;
                });
            } else {
                return this.alumnos;
            }
        },
        deshabilitarConsultar(){            

            
            if((!this.mostrarFiltroGrupo || this.grupoSeleccionado.id > 0) && (!this.mostrarFiltroPeriodo || this.periodoSeleccionado.id>0)){

                if(this.fechaFinal> this.fechaInicial){
                    return false;
                }
                else{
                    return true;
                }                
            }
            else{
                return true;
            }
        },
        deshabilitarAgregarEvento(){
            if(this.rolesUsuario.includes("estudiante")){
                return true;
            }
            
            if(this.grupoSeleccionado.id > 0 && this.periodoSeleccionado.id>0 ){
                return false;
            }
            else{
                return true;
            }
        },
        obtenerCantidadDestinatariosEvento(){
            var strDEstinatarios = "(0) DESTINATARIO(S) : ";

            if(this.editedItem!=null && this.editedItem.eventoDetalles && this.editedItem.eventoDetalles.length && this.editedItem.eventoDetalles.length>0){
                strDEstinatarios = `(${this.editedItem.eventoDetalles.length}) DESTINATARIO(S)`;    
            }
            else if(this.editedItem!=null && this.editedItem.destinatarioUsuario && this.editedItem.destinatarioUsuario!=null){
                var strDEstinatarios = "(1) DESTINATARIO : ";
            }
            else if(this.alumnos!=null && this.alumnos.length){
                
                if(this.estudianteDestinatario!=null && this.estudianteDestinatario.estudianteId!=null && this.estudianteDestinatario.estudianteId!=0){
                    var strDEstinatarios = `(1) DESTINATARIO : ${this.estudianteDestinatario.nombreCompleto}`;
                }
                else{
                    var strDEstinatarios = `(${this.alumnos.length}) DESTINATARIO(S) `;
                }                
            }

            return strDEstinatarios;
        },
        obtenerNombreDestinatarioEvento(){
            var strNombreDEstinatario="";

            if(this.editedItem!=null && this.editedItem.destinatarioUsuario){
                strNombreDEstinatario =`${this.editedItem.destinatarioUsuario.nombres} ${this.editedItem.destinatarioUsuario.apellidos}`;
            }

            return strNombreDEstinatario.toUpperCase()
        },        
        obtenerNombreResponsableEvento(){
            var strNombreResponsable="";

            if(this.editedItem!=null && this.editedItem.responsableUsuario){
                strNombreResponsable =`${this.editedItem.responsableUsuario.nombres} ${this.editedItem.responsableUsuario.apellidos}`;
            }

            return strNombreResponsable.toUpperCase()
        }
    },
    methods: {
    ...mapActions(
        [
            "GetPeriodos",
            "GetGruposPeriodos",
            "GetAlumnosGrupoPerido", 
            "GetTiposEvento",  
            "GetEventos",             
            "ModificarMensaje",
            "GetUsuariosRoles",
            "GetUsuarioById",
            "RegistrarEvento",
            "ActualizarEvento",
            "ActualizarRespuestaEvento",
            "ObtenerHistorialEvento"
        ]),
        initialize() {
            this.idUsuario = this.$store.state.auth.user.profile.sub;
            let datosUsuario ={id:this.idUsuario};            
            this.GetUsuarioById(datosUsuario).then((x)=> (this.usuarioSesion = x.data));            
            this.GetUsuariosRoles().then((x)=> (this.rolesUsuario = x.data));

            this.GetPeriodos().then((x) => (this.periodos = x.data));
            this.GetTiposEvento().then((x) => (this.tiposEvento = x.data));
        },
        btnNuevoEventoClick(){
            this.editedItem =
            {
                id:0,
                periodo:{id:0,nombre:'Periodo'},
                grupo:{id:0,nombre:'Periodo'},
                tipoEvento:{id:0,nombre:'Tipo Evento'},
                tipoEventoId:0,
                fechaRegistro:this.toISOLocal(new Date(Date.now())),
                fechaInicioEvento:null,
                fechaFinEvento:null,
                fechaInicialFecha:null,
                fechaInicialHora:null,
                fechaFinalFecha:null,
                fechaFinalHora:null,
                destinatarioUsuario:null,
                responsableUsuario:this.usuarioSesion,
                eventoDetalles:[],
                asunto:null,
                observaciones:null,
                activo:true,
                editedItem:"blue--text text--lighten-1",
                accion:"Registrar Nuevo Evento"
            }
            this.editedItem.periodo= this.periodoSeleccionado;    
            this.editedItem.grupo = this.grupoSeleccionado; 
            

            this.mostrarBotonGuardarModificar=true;     
            this.mostrarSoloLectura = false;  
            this.deshabilitarModificarGuardar = false;                 
        },
        consultar(){
            this.datosFiltro =
            {
                periodo: this.mostrarFiltroPeriodo? this.periodoSeleccionado.id :0,
                grupo: this.mostrarFiltroGrupo? this.grupoSeleccionado.id:0,
                destinatario: 0 ,
                fechaInicial : this.fechaInicial,
                fechaFinal:this.fechaFinal,
                tipoEvento : this.tipoNotificacionId,
                responsable:  this.idUsuario,                                                
                verInactivos:this.verInactivos
            };
            
            if(this.estudianteDestinatario!=null){
                 this.datosFiltro.destinatario=this.estudianteDestinatario.estudianteId;
            }

            if(this.rolesUsuario.includes("administrador")){
                this.datosFiltro.responsable="";
            }

            if(this.rolesUsuario.includes("estudiante") && !this.mostrarFiltroEstudiante){
                this.datosFiltro.responsable="0";
                this.datosFiltro.destinatario=this.idUsuario;
            }

            if(this.rolesUsuario.includes("docente")){
                this.datosFiltro.responsable=this.idUsuario;
            }

            if(this.datosFiltro.destinatario==null){
                this.datosFiltro.destinatario="0";
            }

            if(this.datosFiltro.responsable==null){
                this.datosFiltro.responsable="0";
            }

            if(this.datosFiltro.tipoEvento==null){
                this.datosFiltro.tipoEvento=0;
            }
                        
            this.limpiarResultados();         
            this.GetEventos(this.datosFiltro).then((x) => (this.agendaEventos = x.data));            
        },
        close(){
            this.dialog=false;
            this.deshabilitarModificarGuardar=true;
            this.dialogHistorial=false;

        },
        closeHistorial(){
            this.dialogHistorial=false;
        },
        obtenerEstado(aceptado){
            let strEstado="PENDIENTE";
            
            if(aceptado===true){
                strEstado="ACEPTADO";
            }
            else if(aceptado===false){
                strEstado="RECHAZADO";
            }

            return strEstado;
        },
        obtenerColorEstado(aceptado){
            let strColor = "blue";

            if(aceptado===true){
                strColor="green";
            }
            else if(aceptado===false){
                strColor="red";
            }

            return strColor;
        },
        obtenerNombreDestinatario(destinatario){
            let nombreCompleto = "";

            if(destinatario!=null){
                nombreCompleto = `${destinatario.nombres} ${destinatario.apellidos}`;  
            }
            else{
                nombreCompleto="Grupal";
            }

            return nombreCompleto;
        },
        format_date(value){
            if (value) {
                var d = new Date(value);
                var años = d.getFullYear().toString();
                var mes = (d.getMonth()+1).toString();
                var dia = d.getDate().toString() ;
                var horas =  d.getHours().toString();
                var minutos =d.getMinutes().toString();

                if(mes.length==1){
                    mes=`0${mes}`;
                }

                if(dia.length==1){
                    dia=`0${dia}`;
                }

                if(horas.length==1){
                    horas=`0${horas}`;
                }

                if(minutos.length==1){
                    minutos=`0${minutos}`;
                }

                var fechaFormato = `${años}-${mes}-${dia} ${horas}:${minutos}`;                
                return  fechaFormato;
            }
        },
        formato_hora(value){
            if(value){
                var formatoHora = null;
                var d = new Date(value);
                var horas =  d.getHours().toString();
                var minutos =d.getMinutes().toString();

                if(horas.length==1){
                    horas=`0${horas}`;
                }

                if(minutos.length==1){
                    minutos=`0${minutos}`;
                }

                formatoHora =`${horas}:${minutos}`;
                return formatoHora;
            }
        },
        toISOLocal(d) {
            var z  = n =>  ('0' + n).slice(-2);
            var zz = n => ('00' + n).slice(-3);
            var off = d.getTimezoneOffset();
            var sign = off > 0? '-' : '+';
            off = Math.abs(off);

            return d.getFullYear() + '-'
                    + z(d.getMonth()+1) + '-' +
                    z(d.getDate()) + 'T' +
                    z(d.getHours()) + ':'  + 
                    z(d.getMinutes()) + ':' +
                    z(d.getSeconds()) + '.' +
                    zz(d.getMilliseconds()) +
                    sign + z(off/60|0) + ':' + z(off%60); 
        },
        validarEncabezadoEvento(){
            var resultado = true;

            this.cambiarFechaInicial();

            if(this.editedItem!=null){
                if(this.editedItem.periodo==null || this.editedItem.periodo.id==null || this.editedItem.periodo.id==0){
                    resultado = false;
                }
                else if(this.editedItem.grupo==null || this.editedItem.grupo.id==null || this.editedItem.grupo.id==0){
                    resultado = false;
                }
                else if(this.editedItem.eventoDetalles==null){
                    resultado = false;
                }
                else if(this.editedItem.responsableUsuario==null || this.editedItem.responsableUsuario.id==null || this.editedItem.responsableUsuario.id==''){
                    resultado = false;
                }
                else if(this.editedItem.fechaRegistro==null){
                    resultado = false;
                }
                else if(this.editedItem.fechaInicialFecha==null || this.editedItem.fechaFinalFecha==null){
                    resultado = false;
                }
                else if(this.editedItem.fechaInicialHora==null || this.editedItem.fechaFinalHora==null){
                    resultado = false;
                }
                else if(new Date(this.editedItem.fechaInicialFecha).setHours(this.editedItem.fechaInicialHora.split(':')[0],this.editedItem.fechaInicialHora.split(':')[1]) > new Date(this.editedItem.fechaFinalFecha).setHours(this.editedItem.fechaFinalHora.split(':')[0],this.editedItem.fechaFinalHora.split(':')[1])){
                    resultado = false;
                }
                else if(this.editedItem.asunto==null || this.editedItem.asunto==''){
                    resultado = false;
                }
                else if(this.editedItem.tipoEvento==null || this.editedItem.tipoEvento.id==null || this.editedItem.tipoEvento.id==0){
                    resultado = false;                    
                }
            }

            this.deshabilitarModificarGuardar = !resultado;

            return resultado;
        },
        cargarEncabezadoEvento(evento){            
            this.editedItem = evento;
            this.editedItem.fechaInicialFecha = new Date(this.editedItem.fechaInicioEvento).toISOString().substr(0, 10);
            this.editedItem.fechaFinalFecha = new Date(this.editedItem.fechaFinEvento).toISOString().substr(0, 10);
            this.editedItem.fechaInicialHora = this.formato_hora(this.editedItem.fechaInicioEvento);
            this.editedItem.fechaFinalHora = this.formato_hora(this.editedItem.fechaFinEvento);
            this.editedItem.observaciones = this.editedItem.eventoDetalles[0].observaciones;                        

            this.dialog = true;
            this.mostrarBotonesRespuesta=false;            
        },
        cambiarFechaInicial(){
            if(this.editedItem!=null && (this.editedItem.fechaFinalFecha=="" || this.editedItem.fechaFinalFecha==null) && this.editedItem.fechaInicialFecha!=""){
                this.editedItem.fechaFinalFecha=this.editedItem.fechaInicialFecha;
            }
        },
        limpiarResultados(){
            this.agendaEventos=[];
        },
        obtenerEstadoRespuesta(evento){
            this.editedItem.estadoRespuesta="PENDIENTE";
            
            let totalDetalles  = 0;
            let totalPendientes =0;
            let totalAceptados =0;
            let totalRechazados =0;
            let aceptadoPorUsuario = null;

            if(evento.eventoDetalles && evento.eventoDetalles.length==1){
                totalDetalles=1;
                if(evento.eventoDetalles[0].aceptado===true){
                   aceptadoPorUsuario=true;
                }
                else if(evento.eventoDetalles[0].aceptado===false){
                    aceptadoPorUsuario=false;
                }             
            }
            else
            {
                totalDetalles = evento.eventoDetalles.length;

                evento.eventoDetalles.forEach(element => {
                    if(element.aceptado===true){
                        totalAceptados++;

                        if(element.destinatarioUsuarioId==this.idUsuario || element.destinatarioUsuarioId== this.datosFiltro.destinatario){                            
                            aceptadoPorUsuario=true;
                        }                        
                    }
                    else if(element.aceptado===false){
                        totalRechazados++;
                    }   
                    else{
                        totalPendientes++;
                    }                 
                });
            }

            if(totalDetalles<=1 || aceptadoPorUsuario!=null){
                this.editedItem.estadoRespuesta="PENDIENTE";
                this.editedItem.colorEstadoRespuesta = "blue--text text--lighten-1"

                if(aceptadoPorUsuario===true){
                    this.editedItem.estadoRespuesta="ACEPTADO";
                    this.editedItem.colorEstadoRespuesta = "green--text text--lighten-1"
                }
                else if(aceptadoPorUsuario===false){
                    this.editedItem.estadoRespuesta="RECHAZADO";
                    this.editedItem.colorEstadoRespuesta = "red--text text--lighten-1"
                }                 
            }                       
            else
            {
                this.editedItem.estadoRespuesta=`Total Aceptado(${totalAceptados}) / Pendiente(${totalPendientes})/ Rechazado(${totalRechazados})`;
                this.editedItem.colorEstadoRespuesta = "blue--text text--lighten-1"
            }
        },
        verLecturaEncabezadoEvento(evento){
            this.cargarEncabezadoEvento(evento);
            this.obtenerEstadoRespuesta(evento);
            this.deshabilitarModificarGuardar = true;
            this.mostrarBotonGuardarModificar=false;
            this.mostrarSoloLectura = true;         
            this.editedItem.accion="Consultar Evento";   
        },
        verEditarEncabezadoEvento(evento){
            this.cargarEncabezadoEvento(evento);
            this.obtenerEstadoRespuesta(evento);
            this.deshabilitarModificarGuardar = false;
            this.mostrarBotonGuardarModificar=true;
            this.mostrarSoloLectura = false;
            this.mostrarBotonesRespuesta=false;            
            this.editedItem.accion="Modificar Evento";   
        },
        verResponderEvento(evento){
            this.cargarEncabezadoEvento(evento);   
            this.obtenerEstadoRespuesta(evento);         
            this.deshabilitarModificarGuardar = false;
            this.mostrarBotonGuardarModificar=false;
            this.mostrarSoloLectura = false;
            this.mostrarBotonesRespuesta=false;            
            this.mostrarBotonesRespuesta=true;      
            this.editedItem.accion="Responder Evento";                               
        },
        save(){
            var datosNuevoEvento={};

            if(this.validarEncabezadoEvento())
            {                
                var strFechaInicio = this.editedItem.fechaInicialFecha.split("-");
                var fechaInicialEvento =new Date(parseInt(strFechaInicio[0]), parseInt(strFechaInicio[1]-1),parseInt(strFechaInicio[2]),0,0,0);
                fechaInicialEvento = new Date(fechaInicialEvento.setHours(this.editedItem.fechaInicialHora.split(':')[0],this.editedItem.fechaInicialHora.split(':')[1],0));

                var strFechaFin = this.editedItem.fechaFinalFecha.split("-");
                var fechaFinalEvento = new Date(parseInt(strFechaFin[0]), parseInt(strFechaFin[1]-1),parseInt(strFechaFin[2]),0,0,0);
                fechaFinalEvento = new  Date(fechaFinalEvento.setHours(this.editedItem.fechaFinalHora.split(':')[0],this.editedItem.fechaFinalHora.split(':')[1],0));

                datosNuevoEvento.FechaInicioEvento = this.toISOLocal(fechaInicialEvento);
                datosNuevoEvento.FechaFinEvento = this.toISOLocal(fechaFinalEvento);

                datosNuevoEvento.Asunto = this.editedItem.asunto;
                datosNuevoEvento.TipoEventoId = this.editedItem.tipoEvento.id;
                datosNuevoEvento.TipoEvento = this.editedItem.tipoEvento;
                datosNuevoEvento.GrupoId = this.editedItem.grupo.id;
                datosNuevoEvento.Grupo = this.editedItem.grupo;
                datosNuevoEvento.PeriodoId = this.editedItem.periodo.id;
                datosNuevoEvento.Periodo = this.editedItem.periodo;

                datosNuevoEvento.ResponsableUsuarioId = "";
                datosNuevoEvento.ResponsableUsuarioId = null;

                if(this.editedItem.responsableUsuario !=null && this.editedItem.responsableUsuario.id && this.editedItem.responsableUsuario.id!=null){
                    datosNuevoEvento.ResponsableUsuarioId = this.editedItem.responsableUsuario.id;
                    datosNuevoEvento.responsableUsuario = this.editedItem.responsableUsuario;
                }

                datosNuevoEvento.DestinatarioUsuarioId ="";
                datosNuevoEvento.DestinatarioUsuario = null;


                if(this.editedItem.id==null || this.editedItem.id<=0){
                    if(this.estudianteDestinatario!=null && this.estudianteDestinatario.estudianteId!=0){
                        datosNuevoEvento.DestinatarioUsuarioId = this.estudianteDestinatario.estudianteId;
                        datosNuevoEvento.DestinatarioUsuario = this.estudianteDestinatario;
                    }
                }

                if(this.editedItem.destinatarioUsuario !=null && this.editedItem.destinatarioUsuario.id && this.editedItem.destinatarioUsuario.id!=null){
                    datosNuevoEvento.DestinatarioUsuarioId = this.editedItem.destinatarioUsuario.id;
                    datosNuevoEvento.DestinatarioUsuario = this.editedItem.destinatarioUsuario;
                }
                
                datosNuevoEvento.Activo = this.editedItem.activo;
                datosNuevoEvento.EventoDetalles= [{observaciones:this.editedItem.observaciones}];

                if(this.editedItem.id ==0 || this.editedItem.id==null)
                {                
                    datosNuevoEvento.Id =0;
                    datosNuevoEvento.FechaRegistro= this.toISOLocal(new Date(Date.now()));
                    
                    this.RegistrarEvento(datosNuevoEvento).then((x) => {                        
                        if(x.data.id > 0){
                            this.ModificarMensaje({ texto: "Nuevo Evento Registrado!" });
                            this.consultar();
                            this.close();
                        }                        
                    });                                                               
                }
                else
                {
                    datosNuevoEvento.Id =this.editedItem.id;
                    datosNuevoEvento.FechaRegistro= this.editedItem.fechaRegistro;
                    this.ActualizarEvento(datosNuevoEvento).then((x) => {                        
                        if(x.data.id > 0){
                            this.ModificarMensaje({ texto: "Evento Actualizado!" });
                            this.consultar();
                            this.close();
                        }
                    });                        
                } 
            }                        
        },
        saveRespuesta(aceptar){
            var respuesta ={};
            
            respuesta.idEvento= this.editedItem.id;
            respuesta.usuarioRespuestaId= this.idUsuario;
            respuesta.observaciones = this.editedItem.respuestaObservaciones!=null ? this.editedItem.respuestaObservaciones.trim():"";
            respuesta.telefonoContacto = this.editedItem.telefonoContacto!=null? this.editedItem.telefonoContacto.trim():"";            
            respuesta.aceptar = aceptar;
            respuesta.redactorPerfil = this.rolesUsuario[0];

            if(respuesta.observaciones==null || respuesta.observaciones=="" || respuesta.telefonoContacto==null || respuesta.telefonoContacto==""){                
                this.ModificarMensaje({ texto: "Debe diligenciar los Campos Requeridos!", color:"error",timeout:3000});                                                                
            }
            else
            {
                this.ActualizarRespuestaEvento(respuesta).then((x) => {                        
                    this.ModificarMensaje({ texto: "Respuesta Enviada"});                                    
                    this.close();                    
                });
            }            
        },
        verHistorial(evento){
            this.dialog=false;
            this.dialogHistorial=true;
            this.eventoHistorial=[];

            let filtroHistorial ={};
            filtroHistorial.idEvento = evento.id;
            filtroHistorial.usuarioAsunto = this.datosFiltro.destinatario;

            this.ObtenerHistorialEvento(filtroHistorial).then((x) => {                        
                this.eventoHistorial = x.data;
            });
        }
    },
    watch: {        
        periodoSeleccionado(newValor) {
            if (newValor.id > 0) {
                this.GetGruposPeriodos(newValor.id).then((x) => (this.grupos = x.data));
            }

            this.alumnos = [];
            this.estudianteDestinatario= {estudianteId : 0,nombreCompleto:""};
            this.grupos = [];
            this.grupoSeleccionado.id = 0;
        },
        grupoSeleccionado(newValor) {
            if (newValor.id > 0) {
                let datos = {};
                datos.grupo = newValor.id;
                datos.periodo = this.periodoSeleccionado.id;
                this.GetAlumnosGrupoPerido(datos).then((x) => (this.alumnos = x.data));
            }
            
            this.alumnos = [];
            this.estudianteDestinatario= {estudianteId : 0,nombreCompleto:""};
        },
        rolesUsuario(){
            if(this.rolesUsuario.includes("estudiante"))
            {
                this.mostrarFiltroEstudiante = false;
                this.mostrarEditarEncabezado = false;
                this.mostrarFiltroGrupo= false;
                this.mostrarFiltroPeriodo = false;
            }
            else{
                this.mostrarFiltroEstudiante = true;
                this.mostrarEditarEncabezado = true;
            }
        }
    }
}
</script>